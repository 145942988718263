html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #0fb3c9;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

body {
  background-color: #000000 !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #166774;;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0fb3c9;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #166774;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #0fb3c9 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 3.5em !important;
  width: 6.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #0fb3c9;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */

@media (max-width: 767px) {
  .poa-logo {
    display: none;
    }
}

@media (min-width: 450px) {
.logo-contact {
  margin-top: 120px !important;
  }
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

.type-wrapper {
  position: relative;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 767px) {
  .type-wrapper {
    margin-bottom: 100px;
  }
}

.typewriter-text {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateY(100%);
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: 450px) {
  .typewriter-text {
  font-size: 2.1rem;
  }
}

.typewriter-text.appear {
  opacity: 1;
  transform: translateY(0);
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-section {
  position: relative !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.home-header {
  padding-top: 30px !important;
  text-align: --webkit-center !important;
}

@media (min-width: 767px) {
  .home-header {
    width: 500px !important;
  }
}

@media (min-width: 767px) {
  .home-header h1 {
    width: 500px !important;
  }
}

.home-content {
  padding: 6rem 0 0rem !important;
  color: whitesmoke;
  text-align: left;
}

.home-content2 {
  padding: 4rem 0 0rem !important;
  color: whitesmoke;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #0fb3c9;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #0fb3c9 !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #0fb3c9 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 4em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-description {
  color: white !important;
  text-align: center;
}

@media (min-width: 767px) {
  .home-about-description {
    margin-bottom: 100px;
  }
}

.paragraph-wrapper {
  text-align: left;
  margin-top: 20px;
}

@media (max-width: 450px) {
  .homeMargin {
    margin-top: 40px;
    }
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #0fb3c9;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #0fb3c9;
}

.home-social-icons:hover {
  color: #0fb3c9;
  box-shadow: 0 0 5px #106868d7;
  text-shadow: 0 0 2px #106868d7;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #0fb3c9 !important;
}

.service-model-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .service-model-title {
    margin-top: 50px !important;
    }
}


/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #106868d7 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 130px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: 640px !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px #0fb3c9 !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
@media (min-width: 450px) {
  .project-card-view:hover {
    transform: scale(1.01) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px #106868d7 !important;
  }
}

.partner-card { transition: all .2s ease-in-out }
.partner-card:hover { transform: scale(1.25) }

.card-img-top {
  opacity: 0.8 !important;
  height: 170px;
}

.btn-primary {
  color: #fff !important;
  background-color: #0fb3c9 !important;
  border-color: #0fb3c9 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #106868d7 !important;
  border-color: #106868d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.1em !important;
  margin-top: 10px !important;
}
.partners {
  margin-top: 50px !important;
}

@media (min-width: 450px) {
.partners {
  margin-top: 80px !important;
  }
}

/* --------- */
/* Slick Slider */
/* --------- */

.slider-item {
  outline: none;
}

.slick-prev:before, .slick-next:before {
  font-size: 40px;
  color: var(--imp-text-color) !important;
}

@media (max-width: 450px) {
  .slick-prev:before, .slick-next:before {
    display: none;
  }
}

.slick-next {
  right: -5px;
}

.slick-dots {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  bottom: 0;
  margin-bottom: -25px !important;
}

.slick-dots li {
  margin: 0 4px;
}

.slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-dots li.slick-active button {
  background-color: var(--imp-text-color) !important;
  border-color: var(--imp-text-color) !important;
}

.slick-dots li button:before {
  font-size: 0px;
}

/* --------- */
/* About */
/* --------- */

@media (min-width: 450px) {
.techstack-wrapper {
  margin-top: 90px !important;
  }
}

.about-section {
  position: relative !important;
  padding-top: 120px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 2em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid #0fb3c9 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px #106868d7 !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

.tech-icons svg {
  font-size: 1.5em !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.1);
  box-shadow: 4px 5px 4px 3px #ffffff !important;
  outline: 2px solid #ffffff;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

.fancy-bullet-points {
  list-style-type: none;
  padding-left: 0;
}

.fancy-bullet-points li::before {
  content: "";
  vertical-align: middle;
}

.bullet-icon {
  color: #0fb3c9;
  margin-right: 10px;
  margin-bottom: 5px;
}

/* --------- */
/* Contact */
/* --------- */
.contact-section {
  position: relative !important;
  padding-top: 120px !important;
  padding-bottom: 585px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

/* --------- */
/* Buttons and stuff */
/* --------- */

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #0fb3c9 !important;
  border-color: #0fb3c9 !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #0fb3c9 !important;
  border-color: #0fb3c9 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #0fb3c9 !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #0fb3c9 !important;
  border-color: #0fb3c9 !important;
}
.fork-btn-inner::after {
  display: none !important;
}

.contact-image {
  width: 60% !important;
}

@media (min-width: 767px) {
  .contact-image {
    margin-top: 100px !important;
    }
}

@media (min-width: 450px) {
.contact-info {
  margin-left: 50px;
  }
}
/* --------- */
/* Homepage timeline */
/* --------- */

.timeline {
  position: relative;
  list-style: none;
  background-color: transparent;
  color: white;
}

@media (min-width: 767px) {
.timeline {
  margin-top: 20px;
  }
}

.timeline:before {
  content: '';
  position: absolute;
  left: 35px;
  width: 2px;
  height: 100%;
  background-color: #0fb3c9;
}

.timeline-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px 0px rgba(15, 179, 201, 0.5);
  color: #0fb3c9;
  border-radius: 30px;
}

.timeline-item:hover .timeline-tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.timeline-tooltip {
  visibility: hidden;
  position: absolute;
  bottom: 70%;
  left: 20%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #0fb3c9;
  padding: 10px;
  border-radius: 30px;
  font-size: 12px;
  transition: visibility 0s, opacity 0.3s;
  z-index: 999;
  width: 250px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(20px);
}

.timeline-icon {
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #0fb3c9;
  border-radius: 50%;
  color: white;
  font-size: 30px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.timeline-content {
  margin-left: 90px;
  font-size: 20px;
  line-height: 70px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 60px;
}

.timeline-item:hover .timeline-icon {
  transform: rotateY(180deg);
}

.timeline-icon-front, .timeline-icon-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.timeline-icon-back {
  transform: rotateY(180deg);
}

.placeholder-color::placeholder {
  color: #fff !important;
}

.input-field:hover, .input-field:focus {
  border: 2px solid #106868d7 !important;
}

.message-border {
  border: 4px solid #0fb3c9;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  margin-top: 50px;
  background-color: #181a27;
  opacity: 0.8;
}

.message-border::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50px;
  border-width: 20px 20px 0;
  border-style: solid;
  border-color: #0fb3c9 transparent;
  display: block;
  width: 0;
}

.submit-button {
  background-color: transparent !important;
  width: 200px;
  border: 4px solid #0fb3c9 !important;
}
